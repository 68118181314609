// Background colours
$backgroundColour: #ffffff;
$codeBackgroundColour: #BEF3DE;
$featureBackgroundColour: #BEF3DE;
$accentColour: #E7CECB;

// Text colours
$headingColour: #a33a83;
$bodyColour: #111111;
$linkColour: #355782;
$hoverColour: lighten($linkColour, 18);
$focusColour: $hoverColour;
$captionColour: #CC627A;
$white: #ffffff;

// Typography
$bodytype: (
  font-family: 'Cabin, sans-serif',
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
);

$headingtype: (
  font-family: '"Passion One", sans-serif',
  regular: 400,
  bold: 400,
  cap-height: 0.75
);

$monospacetype: (
  font-family: 'Inconsolata, monospace',
  regular: 400,
  cap-height: 0.68
);

// Font import, if you're using a non-standard web font
@import url('https://fonts.googleapis.com/css?family=Cabin:400,700|Passion+One:400|Inconsolata');
