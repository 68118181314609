
.header {
  .logo h1 {
    font-weight: normal;
    height: 1.5rem;
    line-height: 1.5rem;
  }
}

.main {
  margin-bottom: 3rem;
}

.footer {
  a:hover {
    color: lighten($headingColour, 18);
  }
  .container {
    padding: 0.5rem 0;
    div {
      margin: 0.5rem;
    }
  }
}

.nav--paginator {
  margin-top: 3rem;
}

.post-meta {
  padding-top: 0;  
}

.article--post h2:first-child {
  margin-bottom: 0;
  a:hover {
    background: none;
  }
  a:after {
    content: ' »'
  }
}

.article--page.content h1:first-child {
  margin-bottom: 1rem;
}

.typeset {
  h1, h2, h3 {
    clear: both;
  }
  p {
    padding-top: 0;
    margin-bottom: 2rem;
  }
  small, .caption {
    font-size: 1rem;
    font-family: unquote(map-get($bodytype, font-family));
    margin-bottom: 1rem;
  }
  ul:not(.list), ol {
    padding-top: 0;
    margin-bottom: 2rem;
    li {
      margin-left: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.twitter-tweet {
  margin: 2em 0 !important;
}
